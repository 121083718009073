export class AccessToken {

    _apiUrl;
    _userRole;

    get apiUrl() {
        return this._apiUrl;
    }

    set apiUrl(value) {
        this._apiUrl = value;
    }

    get userRole() {
        return this._userRole;
    }

    set userRole(value) {
        this._userRole = value;
    }

    canBeUsedFromJsSdk(){
        return this.userRole !== 'MERCHANT'
    }


    constructor(apiUrl, userRole) {
        this._apiUrl = apiUrl;
        this._userRole = userRole;
    }

    static fromJson(value) {
        let userRole = value.userRole ? value.userRole : null;
        let apiUrl = value.apiUrl ? value.apiUrl : null;
        return new AccessToken(apiUrl, userRole);
    }
}
export class OnRenderPaymentMethodEvent {
    /**
     *
     * @param paymentMethod {PaymentMethod}
     * @param defaultPaymentMethodElement {HTMLElement}
     * @param onSelectPaymentMethodCallback {function}
     */
    constructor(paymentMethod, defaultPaymentMethodElement, onSelectPaymentMethodCallback) {
        this._paymentMethod = paymentMethod;
        this._defaultPaymentMethodElement = defaultPaymentMethodElement;
        this._onSelectPaymentMethodCallback = onSelectPaymentMethodCallback;
    }


    get paymentMethod() {
        return this._paymentMethod;
    }

    set paymentMethod(value) {
        this._paymentMethod = value;
    }

    get defaultPaymentMethodElement() {
        return this._defaultPaymentMethodElement;
    }

    set defaultPaymentMethodElement(value) {
        this._defaultPaymentMethodElement = value;
    }

    get onSelectPaymentMethodCallback() {
        return this._onSelectPaymentMethodCallback;
    }

    set onSelectPaymentMethodCallback(value) {
        this._onSelectPaymentMethodCallback = value;
    }
}
export class Address {

    /**
     *
     * @param street {string}
     * @param city {string}
     * @param country {string}
     * @param zipCode {string}
     */
    constructor(street, city, country, zipCode) {
        this._street = street;
        this._city = city;
        this._country = country;
        this._zipCode = zipCode;
    }


    /**
     * @returns {string}
     */
    get street() {
        return this._street;
    }

    /**
     * @param value {string}
     */
    set street(value) {
        this._street = value;
    }

    /**
     * @returns {string}
     */
    get city() {
        return this._city;
    }

    /**
     * @param value {string}
     */
    set city(value) {
        this._city = value;
    }

    /**
     * @returns {string}
     */
    get country() {
        return this._country;
    }

    /**
     * @param value {string}
     */
    set country(value) {
        this._country = value;
    }

    /**
     * @returns {string}
     */
    get zipCode() {
        return this._zipCode;
    }

    /**
     * @param value {string}
     */
    set zipCode(value) {
        this._zipCode = value;
    }

    /**
     * @returns {string}
     */
    get firstName() {
        return this._firstName;
    }

    /**
     * @param value {string}
     */
    set firstName(value) {
        this._firstName = value;
    }

    /**
     * @returns {string}
     */
    get lastName() {
        return this._lastName;
    }

    /**
     * @param value {string}
     */
    set lastName(value) {
        this._lastName = value;
    }

    /**
     * @returns {string}
     */
    get region() {
        return this._region;
    }

    /**
     * @param value {string}
     */
    set region(value) {
        this._region = value;
    }

    serialize() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            street: this.street,
            city: this.city,
            region: this.region,
            country: this.country,
            zipCode: this.zipCode
        };
    }

    /**
     * @param value {json}
     */
    static fromJson(value) {
        let street = value.street ? value.street : null;
        let city = value.city ? value.city : null;
        let country = value.country ? value.country : null;
        let zipCode = value.zipCode ? value.zipCode : null;
        let region = value.region ? value.region : null;
        let firstName =  value.firstName ? value.firstName : null;
        let lastName =  value.lastName ? value.lastName : null;
        const address =  new Address(street, city, country, zipCode);
        address.region = region;
        address.firstName = firstName;
        address.lastName = lastName;
        return address;
    }
}
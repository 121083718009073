import {Callbacks} from "./Callbacks.js";
import {CallbackType} from "./CallbackType.js";
import {Callback} from "./Callback.js";
import {BodyFormat} from "./BodyFormat.js";

export class CallbacksBuilder {

    /**
     * callbacks {Callbacks}
     */
    callbacks;

    /**
     *
     */
    constructor() {
        this.callbacks = new Callbacks();
    }

    /**
     * @param value {string|function}
     * @returns {CallbacksBuilder}
     */
    setSuccess(value) {
        const callback = new Callback();
        callback.type = typeof value === "function" ? CallbackType.FUNCTION : CallbackType.URL;
        callback.value = value;
        this.callbacks.success = callback;
        return this;
    }

    /**
     * @param value {string|function}
     * @returns {CallbacksBuilder}
     */
    setFailure(value) {
        const callback = new Callback();
        callback.type = typeof value === "function" ? CallbackType.FUNCTION : CallbackType.URL;
        callback.value = value;
        this.callbacks.failure = callback;
        return this;
    }

    /**
     * @param url {string}
     * @returns {CallbacksBuilder}
     */
    setRedirectUrl(url) {
        this.callbacks.redirect = url;
        return this;
    }

    /**
     * @param url {string}
     * @returns {CallbacksBuilder}
     */
    setNotificationUrl(url) {
        this.callbacks.notification = url;
        return this;
    }

    /**
     * @param url {string}
     * @returns {CallbacksBuilder}
     */
    setVerifyOrderUrl(url) {
        this.callbacks.verifyOrder = url;
        return this;
    }

    /**
     * @param url {string}
     * @returns {CallbacksBuilder}
     */
    setFormStylingUrl(url) {
        this.callbacks.formStyling = url;
        return this;
    }

    /**
     * @param value {BodyFormat}
     * @returns {CallbacksBuilder}
     */
    setBodyFormat(value) {
        this.callbacks.bodyFormat = value;
        return this;
    }

    /**
     * @returns {Callbacks}
     */
    build() {
        return this.callbacks;
    }
}
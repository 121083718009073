import {Order} from "./Order.js";
import {Context} from "./Context.js";
import {Callbacks} from "./Callbacks.js";
import {Configuration} from "./Configuration.js";

export class Session {
    /**
     * @param sessionId {String}
     * @param order {Order}
     * @param context {Context}
     * @param callbacks {Callbacks}
     * @param configuration {Configuration}
     */
    constructor(sessionId, order, context, callbacks, configuration) {
        this._sessionId = sessionId;
        this._order = order;
        this._context = context;
        this._callbacks = callbacks;
        this._configuration = configuration;
    }

    get sessionId() {
        return this._sessionId;
    }

    set sessionId(value) {
        this._sessionId = value;
    }

    /**
     * @returns {Order}
     */
    get order() {
        return this._order;
    }

    /**
     * @param value {Order}
     */
    set order(value) {
        this._order = value;
    }

    /**
     * @returns {Context}
     */
    get context() {
        return this._context;
    }

    /**
     * @param value {Context}
     */
    set context(value) {
        this._context = value;
    }

    /**
     * @returns {Callbacks}
     */
    get callbacks() {
        return this._callbacks;
    }

    /**
     * @param value {Callbacks}
     */
    set callbacks(value) {
        this._callbacks = value;
    }


    /**
     * @returns {Configuration}
     */
    get configuration() {
        return this._configuration;
    }

    /**
     * @param value {Configuration}
     */
    set configuration(value) {
        this._configuration = value;
    }

    /**
    * @param value {json}
    */
    static fromJson(value) {
        let sessionId = value.sessionId ? value.sessionId : null;
        let order = value.order ? Order.fromJson(value.order) : null;
        let context = value.context ? Context.fromJson(value.context) : null;
        let callbacks = value.callbacks ? Callbacks.fromJson(value.callbacks) : null;
        let configuration = value.configuration ? Configuration.fromJson(value.configuration) : null;

        return new Session(sessionId, order, context, callbacks, configuration);
    }

    serialize() {
        return {
            sessionId : this.sessionId,
            order: this.order ? this.order.serialize() : null,
            context: this.context ? this.context.serialize() : null,
            callbacks: this.callbacks ? this.callbacks.serialize() : null,
            configuration: this.configuration ? this.configuration.serialize() : null
        };
    }
}
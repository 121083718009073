import {Agreement} from "./Agreement.js";
import {AgreementType} from "./AgreementType.js";
import {AgreementChargeFrequency} from "./AgreementChargeFrequency.js";
import {UnscheduledType} from "./UnscheduledType.js";

export class AgreementBuilder {

    /**
     * agreement {Agreement}
     */
    agreement;

    constructor() {
        this.agreement = new Agreement();
    }

    /**
     * @param value {AgreementType}
     * @returns {AgreementBuilder}
     */
    setAgreementType(value) {
        this.agreement.agreementType = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {AgreementBuilder}
     */
    setExpirationDate(value) {
        this.agreement.expirationDate = value;
        return this;
    }

    /**
     * @param value {AgreementChargeFrequency}
     * @returns {AgreementBuilder}
     */
    setFrequency(value) {
        this.agreement.frequency = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {AgreementBuilder}
     */
    setNextChargeDate(value) {
        this.agreement.nextChargeDate = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {AgreementBuilder}
     */
    setAgreementManagementUrl(value) {
        this.agreement.agreementManagementUrl = value;
        return this;
    }

    /**
     * @param value {UnscheduledType}
     * @returns {AgreementBuilder}
     */
    setUnscheduledType(value) {
        this.agreement.unscheduledType = value;
        return this;
    }

    /**
     * @param value {boolean}
     * @returns {AgreementBuilder}
     */
    setAutoReserve(value) {
        this.agreement.autoReserve = value;
        return this;
    }

    /**
     * @param value {boolean}
     * @returns {AgreementBuilder}
     */
    setFallbackToPayment(value) {
        this.agreement.fallbackToPayment = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {AgreementBuilder}
     */
    setName(value) {
        this.agreement.name = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {AgreementBuilder}
     */
    setDescription(value) {
        this.agreement.description = value;
        return this;
    }

    /**
     * @param value {Number}
     * @returns {AgreementBuilder}
     */
    setAmount(value) {
        this.agreement.amount = value;
        return this;
    }

    /**
     * @param value {Number}
     * @returns {AgreementBuilder}
     */
    setInitialAmount(value) {
        this.agreement.initialAmount = value;
        return this;
    }

    /**
     * @returns {Agreement}
     */
    build() {
        return this.agreement;
    }
}
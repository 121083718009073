import {Address} from "./Address.js";

export class AddressBuilder {


    /**
     * {Address}
     */
    address;

    /**
     *
     * @param street {string}
     * @param city {string}
     * @param country {string}
     * @param zipCode {string}
     */
    constructor(street, city, country, zipCode) {
        this.address = new Address(street, city, country, zipCode);
    }

    /**
     * @param value {string}
     * @returns {AddressBuilder}
     */
    setFirstName(value) {
        this.address.firstName = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {AddressBuilder}
     */
    setLastName(value) {
        this.address.lastName = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {AddressBuilder}
     */
    setRegion(value) {
        this.address.region = value;
        return this;
    }

    /**
     * @returns {Address}
     */
    build() {
        return this.address;
    }
}
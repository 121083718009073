import {Address} from "./Address.js";
import {Customer} from "./Customer.js";
import {OrderLine} from "./OrderLine.js";
import {Amount} from "./Amount.js";
import {Order} from "./Order.js";
import {CallbacksBuilder} from "./CallbacksBuilder.js";
import {ConfigurationBuilder} from "./ConfigurationBuilder.js";
import {AltaPayEvent} from "./AltaPayEvent.js";
import {Browser} from "./Browser.js";
import {Context} from "./Context.js";
import {PaymentMethod} from "./PaymentMethod.js";
import {PaymentMethodMetadata} from "./PaymentMethodMetadata.js";
import {OnRenderPaymentMethodEvent} from "./OnRenderPaymentMethodEvent.js";
import {AgreementBuilder} from "./AgreementBuilder.js";
import {OrderLineBuilder} from "./OrderLineBuilder.js";
import {AddressBuilder} from "./AddressBuilder.js";
import {CustomerBuilder} from "./CustomerBuilder.js";


/**
 * @class
 * @classdesc AltaPayFactory is an object that provides helper functions to create AltaPay SDK objects
 * @hideconstructor
 */
export class AltaPayFactory {

    /**
     * Helper function to create a ready to use {@link Address} object.
     *
     * @example
     * AltaPayFactory.getAddress('Gyngemose Parkvej 50', 'Soeborg', 'Denmark', '2860')
     *
     * @param street {string}
     * @param city {string}
     * @param country {string}
     * @param zipCode {string}
     * @returns {Address}
     */
    getAddress(street, city, country, zipCode) {
        return new Address(street, city, country, zipCode);
    }

    /**
     * Helper function to create {@link AddressBuilder} used to configure {@link Address}.
     *
     * @example
     * AltaPayFactory.getAddressBuilder('Gyngemose Parkvej 50', 'Soeborg', 'Denmark', '2860')
     *
     * See {@link AddressBuilder} JSDoc for more details on how to use it to configure optional fields of {@link Address}
     *
     * @param street {string}
     * @param city {string}
     * @param country {string}
     * @param zipCode {string}
     * @returns {AddressBuilder}
     */
    getAddressBuilder(street, city, country, zipCode) {
        return new AddressBuilder(street, city, country, zipCode);
    }

    /**
     * Helper function to create a ready to use {@link Customer} object.
     *
     * @example
     * AltaPayFactory.getCustomer(
     *     'John',
     *     'Doe',
     *     'example@email.com',
     *     '+4560020030'
     *     AltaPayFactory.getAddress('Gyngemose Parkvej 50', 'Soeborg', 'Denmark', '2860'),
     *     AltaPayFactory.getAddress('Gyngemose Parkvej 50', 'Soeborg', 'Denmark', '2860')
     * )
     *
     * @param {string} firstName of the customer
     * @param {string} lastName  of the customer
     * @param {string} email  of the customer
     * @param {string} phoneNumber  of the customer
     * @param {Address} billingAddress that will be used for payment processing,
     *                                 use {@link AltaPayFactory#getAddress} to create this object
     * @param {Address} shippingAddress that will be used for shipment processing,
     *                                  use {@link AltaPayFactory#getAddress} to create this object
     * @returns {Customer} ready to use customer object that can interact with AltaPay Checkout API.
     */
    getCustomer(firstName, lastName, email, phoneNumber, billingAddress, shippingAddress) {
        return this.getCustomerBuilder()
            .setFirstName(firstName)
            .setLastName(lastName)
            .setEmail(email)
            .setPhoneNumber(phoneNumber)
            .setBillingAddress(billingAddress)
            .setShippingAddress(shippingAddress)
            .build();
    }

    /**
     * Helper function to create {@link CustomerBuilder} used to configure {@link Customer}.
     * See {@link CustomerBuilder} JSDoc for more details on how to use it to configure {@link Customer}
     *
     * @returns {CustomerBuilder} builder object used to specify the customer details
     */
    getCustomerBuilder() {
        return new CustomerBuilder();
    }

    /**
     * Helper function to create a ready to user {@link Amount} object.
     *
     * @example
     * AltaPayFactory.getAmount(30098.52, 'DKK')
     *
     * @param {string} value representing price
     * @param {string} currency currency associated with the price          // TODO: @Refactor("should we have validation on JS side here or some enum?")
     * @returns {Amount} ready to use amount object that can interact with AltaPay Checkout API.
     */
    getAmount(value, currency) {
        return new Amount(value, currency);
    }

    /**
     * Helper function to create a ready to use {@link OrderLine} object with minimal required data.
     *
     * @example
     * AltaPayFactory.getOrderLine('123981239', 'Lomi 2-person sofa', 1, 28010.50)
     *
     * @param {string} itemId identifier of order item
     * @param {string} description short description of order item
     * @param {number} quantity amount of a given item
     * @param {number} unitPrice unit price
     * @returns {OrderLine} ready to use order line that can interact with AltaPay Checkout API.
     */
    getOrderLine(itemId, description, quantity, unitPrice) {
        return new OrderLine(itemId, description, quantity, unitPrice);
    }


    /**
     * Helper function to create {@link OrderLineBuilder} used to configure {@link OrderLine}.
     *
     * @example
     * AltaPayFactory.getOrderLineBuilder('123981239', 'Lomi 2-person sofa', 1, 28010.50);
     *
     * See {@link OrderLineBuilder} JSDoc for more details on how to use it to configure optional fields of {@link OrderLine}
     *
     * @param {string} itemId identifier of order item
     * @param {string} description short description of order item
     * @param {number} quantity amount of a given item
     * @param {number} unitPrice unit price
     * @returns {OrderLineBuilder} builder object used to configure order line for the checkout session.
     */
    getOrderLineBuilder(itemId, description, quantity, unitPrice) {
        return new OrderLineBuilder(itemId, description, quantity, unitPrice);
    }

    /**
     * Helper function to create a ready to use {@link Order} object.
     *
     * @example
     * AltaPayFactory.getOrder(
     *     '123981239',
     *      AltaPayFactory.getAmount(30098.52, 'DKK'),
     *      [
     *          AltaPayFactory.getOrderLine('123981239', 'Lomi 2-person sofa', 1, 28010.50),
     *          AltaPayFactory.getOrderLine('123981240', 'Delivery', 1, 2078.02)
     *      ],
     *      AltaPayFactory.getCustomer(
     *          'John',
     *          'Doe',
     *          AltaPayFactory.getAddress('Gyngemose Parkvej 50', 'Soeborg', 'Denmark', '2860'),
     *          AltaPayFactory.getAddress('Gyngemose Parkvej 50', 'Soeborg', 'Denmark', '2860')
     *      )
     * )
     *
     * @param {string} orderId merchant's identifier of the order
     * @param {Amount} amount amount of money to be paid for order in the specific currency,
     *                        use {@link AltaPayFactory#getAmount} to create this object
     * @param {OrderLine[]} orderLines list of order lines (items in the cart) that constitutes the order,
     *                                 use {@link AltaPayFactory#getOrderLine} to create this object
     * @param {Customer} customer data required to process the payment for the order,
     *                            use {@link AltaPayFactory#getCustomer} to create this object
     * @param {Map<string, string> | null} transactionInfo optional map of string key - value pairs.
     * @returns {Order} ready to use order that can interact with AltaPay Checkout API.
     */
    getOrder(orderId, amount, orderLines, customer, transactionInfo = null) {
        return new Order(orderId, amount, orderLines, customer, transactionInfo);
    }

    /**
     * Helper function to create a ready to use {@link Browser} object.
     *
     * @example
     * AltaPayFactory.getBrowser(
     *     'Mozilla/5.0',
     *     'en',
     *     'www.example.com/checkout`,
     *     1080,
     *     1920
     * )
     *
     * @param {string} agent browser's user agent
     * @param {string} language browser's language,
     * @param {string} origin website origin,
     * @param {number} screenHeight browser's screen height,
     * @param {number} screenWidth browser's screen width,
     * @returns {Browser} ready to use browser object that can interact with AltaPay Checkout API.
     */
    getBrowser(agent, language, origin, screenHeight, screenWidth) {
        return new Browser(agent, language, origin, screenHeight, screenWidth);
    }

    /**
     * Helper function to create a ready to use {@link Context} object.
     *
     * @example
     * AltaPayFactory.getContext(
     *     AltaPayFactory.getBrowser(
     *          'Mozilla/5.0',
     *          'en',
     *          1080,
     *          1920
     *      )
     * )
     *
     * @param {Browser} browser customer browser required to process the payment for the order
     * @returns {Context} ready to use context object that can interact with AltaPay Checkout API.
     */
    getContext(browser) {
        return new Context(browser);
    }

    /**
     * Helper function to create a ready to use {@link PaymentMethod} object.
     *
     * @example
     * AltaPayFactory.getPaymentMethod(
     *     "dce40b43-13db-4307-8013-b41aa8f2a605",
     *     "Credit / Debit Card",
     *     "https://example.com/logo.png",
     *     AltaPayFactory.getPaymentMethodMetadata(
     *          "AltaPay Card Test Terminal",
     *          "merchant.identifier",
     *          false,
     *          false
     *      )
     * )
     *
     * @param {string} id Identifier of the payment method.
     * @param {string} description Description of the payment method.
     * @param {string} logoUrl URL to the logo of the payment method to be displayed on render method
     * @param {PaymentMethodMetadata} paymentMethodMetadata payment method metadata object that can be used on displaying available options
     * @param {string} name Payment method name.
     * @returns {PaymentMethod} ready to use payment method object that can be used on displaying available options.
     */
    getPaymentMethod(id, description, logoUrl, paymentMethodMetadata, name) {
        return new PaymentMethod(id, description, logoUrl, paymentMethodMetadata, name);
    }

    /**
     * Helper function to create a ready to use {@link PaymentMethodMetadata} object.
     *
     * @example
     * AltaPayFactory.getPaymentMethodMetadata(
     *     "AltaPay Card Test Terminal",
     *     "merchant.identifier",
     *     false,
     *     false
     * )
     *
     * @param {string} terminalName Identifier of the payment method.
     * @param {string} merchantId Identifier of the merchant. Available only for some payment methods like ApplePay.
     * @param {boolean} canUseCredit flag indicating id the payment method can use credited amount
     * @param {boolean} canIssueNewCredit flag indicating id the payment method can issue credit
     * @returns {PaymentMethodMetadata} ready to use payment method metadata object that can be used on displaying available options.
     */
    getPaymentMethodMetadata(terminalName, merchantId, canUseCredit, canIssueNewCredit) {
        return new PaymentMethodMetadata(terminalName, merchantId, canUseCredit, canIssueNewCredit);
    }

    /**
     * Helper function to create {@link CallbacksBuilder} used to configure {@link Callbacks}.
     * See {@link CallbacksBuilder} JSDoc for more details on how to use it to configure {@link Callbacks}
     *
     * @returns {CallbacksBuilder} builder object used to configure callbacks for the checkout session.
     */
    getCallbacksBuilder() {
        return new CallbacksBuilder();
    }

    /**
     * Helper function to create {@link ConfigurationBuilder} used to configure {@link Configuration}.
     * See {@link ConfigurationBuilder} JSDoc for more details on how to use it to configure {@link Configuration}
     *
     * @returns {ConfigurationBuilder} builder object used to create the configuration of the checkout session.
     */
    getConfigurationBuilder() {
        return new ConfigurationBuilder();
    }

    /**
     * Helper function to create {@link AgreementBuilder} used to set {@link Agreement} parameters.
     * See {@link AgreementBuilder} JSDoc for more details on how to use it to set {@link Agreement} parameters
     *
     * @returns {AgreementBuilder} builder object used to create the agreement of the checkout session.
     */
    getAgreementBuilder() {
        return new AgreementBuilder();
    }

    /**
     * Helper function to create {@link AltaPayEvent} object.
     *
     * @example
     * AltaPayFactory.getAltaPayEvent(
     *     EventType.PAYMENT,
     *     "dce40b43-13db-4307-8013-b41aa8f2a605",
     *     "1231/2023",
     *     PaymentStatus.SUCCEEDED,
     *     "Payment succeeded"
     * )
     *
     * @example
     * AltaPayFactory.getAltaPayEvent(
     *     EventType.RENDERING,
     *     null,
     *     "1231/2023",
     *     PaymentStatus.ERROR,
     *     "No payment methods available."
     * )
     *
     * @param {EventType} type of the event
     * @param {string | null} paymentId indicating which payment this event is referring to
     * @param {string} shopOrderId indicating which customer's order this event is referring to
     * @param {string} status overall status of the payment process
     * @param {string | null} errorMessage additional error message that describes more details about the event @deprecated
     * @param {ErrorDetails | null} errorDetails additional error message that describes more details about the event
     * @returns {AltaPayEvent}  ready to use event that can interact with AltaPay Checkout API.
     */
    getAltaPayEvent(type, paymentId, shopOrderId, status, errorMessage, errorDetails) {
        return new AltaPayEvent(type, paymentId, shopOrderId, status, errorMessage, errorDetails);
    }

    /**
     * Helper function to create {@link OnRenderPaymentMethodEvent} object.
     *
     * @param {PaymentMethod} paymentMethod payment method information
     * @param {HTMLElement} defaultPaymentMethodElement the prebuild HTMLElement to be rendered in case you don't want to create your own implementation
     * @param {OnSelectPaymentMethodCallback} onSelectPaymentMethodCallback function to be attached to the component when the consumer select the payment method
     * @returns {OnRenderPaymentMethodEvent}  ready to use on select payment method event that can interact with AltaPay Checkout API.
     */
    getOnSelectPaymentMethodEvent(paymentMethod, defaultPaymentMethodElement, onSelectPaymentMethodCallback) {
        return new OnRenderPaymentMethodEvent(paymentMethod, defaultPaymentMethodElement, onSelectPaymentMethodCallback);
    }
}
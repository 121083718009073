export class Amount {
    /**
     * @param value {number}
     * @param currency {string}
     * @constructor
     */
    constructor(value, currency) {
        this._value = value;
        this._currency = currency;
    }

    /**
     * @returns {number}
     */
    get value() {
        return this._value;
    }

    /**
     * @param value {number}
     */
    set value(value) {
        this._value = value;
    }

    /**
     * @returns {string}
     */
    get currency() {
        return this._currency;
    }

    /**
     * @param value {string}
     */
    set currency(value) {
        this._currency = value;
    }

    serialize() {
        return {value: this.value, currency: this.currency};
    }

    /**
     * @param value {json}
     */
    static fromJson(jsonValue) {
        let value = jsonValue.value ? jsonValue.value : 0;
        let currency = jsonValue.currency ? jsonValue.currency : null;
        return new Amount(value, currency);
    }
}
export class Callback {
    constructor() {
    }

    /**
     * @returns {CallbackType}
     */
    get type() {
        return this._type;
    }

    /**
     * @param value {CallbackType}
     */
    set type(value) {
        this._type = value;
    }

    /**
     * @returns {string}
     */
    get value() {
        return this._value;
    }

    /**
     * @param value {string}
     */
    set value(value) {
        this._value = value;
    }

    serialize() {
        return {type: this.type, value: this.value};
    }

    /**
     * @param jsonValue {json}
     */
    static fromJson(jsonValue) {
        let callback = new Callback();
        callback.type = jsonValue.type ? jsonValue.type : null;
        callback.value = jsonValue.value ? jsonValue.value : null;

        return callback;
    }
}
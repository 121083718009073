import {AltaPayUtils} from "../AltaPayUtils.js";

export class AltaPayApplePay {

    applePaySession;
    token;
    sessionId;
    paymentMethodId;
    order;
    merchantIdentifier;
    authorizeUrl;
    sessionUrl;
    operatingCountry;

    constructor(
        token,
        sessionId,
        paymentMethodId,
        order,
        data) {
        this.token = token;
        this.sessionId = sessionId;
        this.paymentMethodId = paymentMethodId;
        this.order = order;
        this.merchantIdentifier = data.merchantIdentifier;
        this.authorizeUrl = data.authorizeUrl;
        this.sessionUrl = data.sessionUrl;
        this.operatingCountry = data.operatingCountry;
    }

    /**
     *
     * @returns {Promise}
     */
    onRenderCheck() {
        const that = this;
        if (window.ApplePaySession !== undefined) {
            const promise = ApplePaySession.canMakePaymentsWithActiveCard(that.merchantIdentifier);
            return promise.then((function (canMakePayments) {
                return canMakePayments ? Promise.resolve() : Promise.reject();
            }));
        }
        return Promise.reject();
    }

    onInitiatePayment() {
        const that = this;
        const session = new ApplePaySession(14, that.createApplePayPaymentRequest(that.operatingCountry, that.order));
        that.applePaySession = session;
        session.onvalidatemerchant = that.requestSession.bind(this);
        session.onpaymentmethodselected = that.updatePaymentMethodFees.bind(this);
        session.onpaymentauthorized = that.onpaymentauthorised.bind(this);
        session.begin();
    }

    requestSession(validateMerchantEvent) {
        const that = this;
        let sessionRequest = {
            "validationUrl": validateMerchantEvent.validationURL,
            "sessionId": that.sessionId,
            "paymentMethodId": that.paymentMethodId,
        };

        fetch(that.sessionUrl, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + that.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sessionRequest)
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error("couldn't validate session");
            }
        }).then(merchantSession => {
            console.log(merchantSession)
            that.applePaySession.completeMerchantValidation(merchantSession)
        }).catch(error => {
            console.error(error);
        });
    }


    updatePaymentMethodFees(payload) {
        const that = this;
        that.applePaySession.completePaymentMethodSelection(
            {
                newTotal: {
                    label: that.order.orderId,
                    type: "final",
                    amount: that.order.amount.value
                },
                newLineItems: []
            }
        );
    }

    onpaymentauthorised(paymentAuthorizedEvent) {
        const that = this;

        let payment = paymentAuthorizedEvent.payment;
        let authorizeRequest = {
            "sessionId": that.sessionId,
            "paymentMethodId": that.paymentMethodId,
            "providerData": payment.token
        };
        fetch(that.authorizeUrl, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + that.token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(authorizeRequest)
        }).then(response => {
            if (response.status !== 200) {
                throw 'Received status: ' + response.status;
            }
            return response.json();
        }).then(data => {
                that.paymentId = data.paymentId;
                let result = {};
                result.status = ApplePaySession.STATUS_FAILURE;
                result.errors = [];
                if (data.status === 'succeeded') {
                    result.status = ApplePaySession.STATUS_SUCCESS;
                }
                that.applePaySession.completePayment(result)
                if (data.resultUrl) {
                    window.AltaPayUtils.redirect(data.resultUrl);
                } else if(data.resultContent){
                    that.createIFrameWithCallbackContent(data.resultContent);
                }
            }
        ).catch((error) => {
            console.error('Error:', error);
        });
    }

    /**
     *
     * @param htmlContent
     */
    createIFrameWithCallbackContent(htmlContent){
        const background = document.createElement("div");
        background.className = 'AltaPayEmbeddedApplePayResultsBackground';
        background.style.backgroundColor = 'white';
        background.style.position = 'fixed';
        background.style.top = '0px';
        background.style.left = '0px';
        background.style.width = '100%';
        background.style.height = '100%';

        const frame = document.createElement("iframe");
        frame.className = 'AltaPayEmbeddedApplePayResults';
        frame.setAttribute('frameBorder', '0');
        frame.setAttribute('scrolling', 'auto');
        frame.setAttribute('style','position:fixed;top:0px;left:0px;width:100%;height:100%;');
        frame.srcdoc=htmlContent;

        document.body.appendChild(background);
        background.appendChild(frame);
    }

    /**
     *
     * @param {String} operatingCountry
     * @param {Order} order
     * @returns {{total: {label}, countryCode: string, supportedNetworks, merchantCapabilities, currencyCode: string}}
     */
    createApplePayPaymentRequest(operatingCountry, order) {
        let billingAddress = null;
        let shippingAddress = null;
        if(order.customer){
            billingAddress = order.customer.billingAddress;
            shippingAddress = order.customer.shippingAddress;
        }

        return {
            countryCode: operatingCountry,
            currencyCode: order.amount.currency,
            supportedNetworks: ["visa", "mastercard"],
            merchantCapabilities: ["supports3DS"],
            total: {
                label: order.orderId,
                type: "final",
                amount: order.amount.value
            },
            lineItems: this.getOrderLines(order),
            billingContact: this.getAddress(order.customer, billingAddress),
            shippingContact: this.getAddress(order.customer, shippingAddress)
        };
    }

    /**
     *
     * @param {Order} order
     * @returns {*[]}
     */
    getOrderLines(order) {
        let orderLines = [];
        if (order && order.orderLines) {
            order.orderLines.forEach(ol => {

                orderLines.push({
                    label: ol.description,
                    type: "final",
                    amount: ol.unitPrice //this is currently done like that, and it is wrong!!!
                })
            });
        }
        return orderLines;
    }

    getAddress(customer, address) {
        if (customer == null) {
            return null;
        }
        if (address == null) {
            return {
                givenName: customer.firstName,
                familyName: customer.lastName,
                addressLines: [],
                locality: null,
                country: null,
                postalCode: null
            };
        }
        return {
            givenName: customer.firstName,
            familyName: customer.lastName,
            addressLines: [
                address.street
            ],
            locality: address.city,
            country: address.country,
            postalCode: address.zipCode
        };
    }
}


import {Callback} from "./Callback.js";
import {BodyFormat} from "./BodyFormat.js";

export class Callbacks {

    /**
     *
     */
    constructor() {
    }

    /**
     * @returns {Callback}
     */
    get success() {
        return this._success;
    }

    /**
     * @param value {Callback}
     */
    set success(value) {
        this._success = value;
    }

    /**
     * @returns {Callback}
     */
    get failure() {
        return this._failure;
    }

    /**
     * @param value {Callback}
     */
    set failure(value) {
        this._failure = value;
    }

    /**
     * @returns {string}
     */
    get redirect() {
        return this._redirect;
    }

    /**
     * @param value {string}
     */
    set redirect(value) {
        this._redirect = value;
    }

    /**
     * @returns {string}
     */
    get notification() {
        return this._notification;
    }

    /**
     * @param value {string}
     */
    set notification(value) {
        this._notification = value;
    }

    /**
     * @returns {string}
     */
    get verifyOrder() {
        return this._verifyOrder;
    }

    /**
     * @param value {string}
     */
    set verifyOrder(value) {
        this._verifyOrder = value;
    }

    /**
     * @returns {string}
     */
    get formStyling() {
        return this._formStyling;
    }

    /**
     * @param value {string}
     */
    set formStyling(value) {
        this._formStyling = value;
    }

    /**
     * @returns {BodyFormat}
     */
    get bodyFormat() {
        return this._bodyFormat;
    }

    /**
     * @param value {BodyFormat}
     */
    set bodyFormat(value) {
        this._bodyFormat = value;
    }

    serialize() {
        return {
            success: this.success ? this.success.serialize() : null,
            failure: this.failure ? this.failure.serialize() : null,
            redirect: this.redirect,
            notification: this.notification,
            verifyOrder: this.verifyOrder,
            formStyling: this.formStyling,
            bodyFormat: this.bodyFormat};
    }

    /**
     * @param jsonValue {json}
     */
    static fromJson(jsonValue) {
        let callbacks = new Callbacks();
        callbacks.success = jsonValue.success ? Callback.fromJson(jsonValue.success) : null;
        callbacks.failure = jsonValue.failure ? Callback.fromJson(jsonValue.failure) : null;
        callbacks.redirect = jsonValue.redirect ? jsonValue.redirect : null;
        callbacks.notification = jsonValue.notification ? jsonValue.notification : null;
        callbacks.verifyOrder = jsonValue.verifyOrder ? jsonValue.verifyOrder : null;
        callbacks.formStyling = jsonValue.formStyling ? jsonValue.formStyling : null;
        callbacks.bodyFormat = jsonValue.bodyFormat ? jsonValue.bodyFormat : null;

        return callbacks;
    }
}
export class AltaPayEvent {
    /**
     *
     * @param type {EventType}
     * @param paymentId {string}
     * @param shopOrderId {string}
     * @param status {string}
     * @param errorMessage {string|null}  @deprecated
     * @param errorDetails {ErrorDetails}
     */
    constructor(type, paymentId, shopOrderId, status, errorMessage, errorDetails) {
        this._type = type;
        this._paymentId = paymentId;
        this._shopOrderId = shopOrderId;
        this._status = status;
        this._errorMessage = errorMessage
        this._errorDetails = errorDetails
    }

    /**
     * @returns {EventType}
     */
    get type() {
        return this._type;
    }

    /**
     * @param value {EventType}
     */
    set type(value) {
        this._type = value;
    }

    /**
     * @returns {string}
     */
    get paymentId() {
        return this._paymentId;
    }

    /**
     * @param value {string}
     */
    set paymentId(value) {
        this._paymentId = value;
    }

    /**
     * @returns {string}
     */
    get shopOrderId() {
        return this._shopOrderId;
    }

    /**
     * @param value {string}
     */
    set shopOrderId(value) {
        this._shopOrderId = value;
    }

    /**
     * @returns {string}
     */
    get status() {
        return this._status;
    }

    /**
     * @param value {string}
     */
    set status(value) {
        this._status = value;
    }

    /**
     * @returns {string}
     */
    get errorMessage() {
        return this._errorMessage;
    }

    /**
     * @param value {string}
     */
    set errorMessage(value) {
        this._errorMessage = value;
    }

    get errorDetails() {
        return this._errorDetails;
    }

    set errorDetails(value) {
        this._errorDetails = value;
    }
}
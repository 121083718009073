import {AgreementType} from "./AgreementType.js";
import {AgreementChargeFrequency} from "./AgreementChargeFrequency.js";
import {UnscheduledType} from "./UnscheduledType.js";

export class Agreement {

    constructor() {
    }

    /**
     * @returns {AgreementType}
     */
    get agreementType() {
        return this._agreementType;
    }

    /**
     * @param value {AgreementType}
     */
    set agreementType(value) {
        this._agreementType = value;
    }

    /**
     * @returns {string}
     */
    get expirationDate() {
        return this._expirationDate;
    }

    /**
     * @param value {string}
     */
    set expirationDate(value) {
        this._expirationDate = value;
    }

    /**
     * @returns {AgreementChargeFrequency}
     */
    get frequency() {
        return this._frequency;
    }

    /**
     * @param value {AgreementChargeFrequency}
     */
    set frequency(value) {
        this._frequency = value;
    }

    /**
     * @returns {String}
     */
    get nextChargeDate() {
        return this._nextChargeDate;
    }

    /**
     * @param value {string}
     */
    set nextChargeDate(value) {
        this._nextChargeDate = value;
    }

    /**
     * @returns {String}
     */
    get agreementManagementUrl() {
        return this._agreementManagementUrl;
    }

    /**
     * @param value {string}
     */
    set agreementManagementUrl(value) {
        this._agreementManagementUrl = value;
    }

    /**
     * @returns {UnscheduledType}
     */
    get unscheduledType() {
        return this._unscheduledType;
    }

    /**
     * @param value {UnscheduledType}
     */
    set unscheduledType(value) {
        this._unscheduledType = value;
    }

    /**
     * @returns {boolean}
     */
    get autoReserve() {
        return this._autoReserve;
    }

    /**
     * @param value {boolean}
     */
    set autoReserve(value) {
        this._autoReserve = value;
    }

    /**
     * @returns {boolean}
     */
    get fallbackToPayment() {
        return this._fallbackToPayment;
    }

    /**
     * @param value {boolean}
     */
    set fallbackToPayment(value) {
        this._fallbackToPayment = value;
    }

    /**
     * @returns {String}
     */
    get name() {
        return this._name;
    }

    /**
     * @param value {String}
     */
    set name(value) {
        this._name = value;
    }

    /**
     * @returns {String}
     */
    get description() {
        return this._description;
    }

    /**
     * @param value {String}
     */
    set description(value) {
        this._description = value;
    }

    /**
     * @returns {Number}
     */
    get amount() {
        return this._amount;
    }

    /**
     * @param value {Number}
     */
    set amount(value) {
        this._amount = value;
    }

    /**
     * @returns {Number}
     */
    get initialAmount() {
        return this._initialAmount;
    }

    /**
     * @param value {Number}
     */
    set initialAmount(value) {
        this._initialAmount = value;
    }

    serialize() {
        return {
            agreementType: this.agreementType,
            expirationDate: this.expirationDate,
            frequency: this.frequency,
            nextChargeDate: this.nextChargeDate,
            agreementManagementUrl: this.agreementManagementUrl,
            unscheduledType: this.unscheduledType,
            autoReserve: this.autoReserve,
            fallbackToPayment: this.fallbackToPayment,
            name: this.name,
            description: this.description,
            amount: this.amount,
            initialAmount: this.initialAmount
        };
    }

    /**
     * @param jsonValue {json}
     */
    static fromJson(jsonValue) {
        let agreement = new Agreement();
        agreement.agreementType = jsonValue.agreementType ? jsonValue.agreementType : null;
        agreement.expirationDate = jsonValue.expirationDate ? jsonValue.expirationDate : null;
        agreement.frequency = jsonValue.frequency ? jsonValue.frequency : null;
        agreement.nextChargeDate = jsonValue.nextChargeDate ? jsonValue.nextChargeDate : null;
        agreement.agreementManagementUrl = jsonValue.agreementManagementUrl ? jsonValue.agreementManagementUrl : null;
        agreement.unscheduledType = jsonValue.unscheduledType ? jsonValue.unscheduledType : null;
        agreement.autoReserve = jsonValue.autoReserve ? jsonValue.autoReserve : false;
        agreement.fallbackToPayment = jsonValue.fallbackToPayment ? jsonValue.fallbackToPayment : false;
        agreement.name = jsonValue.name ? jsonValue.name : null;
        agreement.description = jsonValue.description ? jsonValue.description : null;
        agreement.amount = jsonValue.amount ? jsonValue.amount : null;
        agreement.initialAmount = jsonValue.initialAmount ? jsonValue.initialAmount : null;

        return agreement;
    }
}

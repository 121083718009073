import {Address} from "./Address.js";

export class Customer {

    constructor() {

    }

    /**
     * @returns {string}
     */
    get firstName() {
        return this._firstName;
    }

    /**
     * @param value {string}
     */
    set firstName(value) {
        this._firstName = value;
    }

    /**
     * @returns {string}
     */
    get lastName() {
        return this._lastName;
    }

    /**
     * @param value {string}
     */
    set lastName(value) {
        this._lastName = value;
    }

    /**
     * @returns {string}
     */
    get email() {
        return this._email;
    }

    /**
     * @param value {string}
     */
    set email(value) {
        this._email = value;
    }

    /**
     * @returns {string}
     */
    get phoneNumber() {
        return this._phoneNumber;
    }

    /**
     * @param value {string}
     */
    set phoneNumber(value) {
        this._phoneNumber = value;
    }

    /**
     * @returns {Address}
     */
    get billingAddress() {
        return this._billingAddress;
    }

    /**
     * @param value {Address}
     */
    set billingAddress(value) {
        this._billingAddress = value;
    }

    /**
     * @returns {Address}
     */
    get shippingAddress() {
        return this._shippingAddress;
    }

    /**
     * @param value {Address}
     */
    set shippingAddress(value) {
        this._shippingAddress = value;
    }

    /**
     * @returns {string}
     */
    get username() {
        return this._username;
    }

    /**
     * @param value
     */
    set username(value) {
        this._username = value;
    }

    /**
     * @returns {string}
     */
    get birthdate() {
        return this._birthdate;
    }

    /**
     * @param value
     */
    set birthdate(value) {
        this._birthdate = value;
    }

    serialize() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            username: this.username,
            birthdate: this.birthdate,
            billingAddress: this.billingAddress ? this.billingAddress.serialize() : null,
            shippingAddress: this.shippingAddress ? this.shippingAddress.serialize() : null
        };
    }

    /**
     * @param value {json}
     */
    static fromJson(value) {
        const customer = new Customer();
        customer.firstName = value.firstName ? value.firstName : null;
        customer.lastName = value.lastName ? value.lastName : null;
        customer.email = value.email ? value.email : null;
        customer.phoneNumber = value.phoneNumber ? value.phoneNumber : null;
        customer.username = value.username ? value.username : null;
        customer.birthdate = value.birthdate ? value.birthdate : null;
        customer.billingAddress = value.billingAddress ? Address.fromJson(value.billingAddress) : null;
        customer.shippingAddress = value.shippingAddress ? Address.fromJson(value.shippingAddress) : null;
        return customer;
    }
}
import {Agreement} from "./Agreement.js";
import {PaymentType} from "./PaymentType.js";

export class Configuration {

    /**
     *
     */
    constructor() {
    }

    /**
     * @returns {PaymentType}
     */
    get paymentType() {
        return this._paymentType;
    }

    /**
     * @param value {PaymentType}
     */
    set paymentType(value) {
        this._paymentType = value;
    }

    /**
     * @returns {Agreement}
     */
    get agreement() {
        return this._agreement;
    }

    /**
     * @param value {Agreement}
     */
    set agreement(value) {
        this._agreement = value;
    }

    /**
     * @returns {boolean}
     */
    get autoCapture() {
        return this._autoCapture;
    }

    /**
     * @param value {boolean}
     */
    set autoCapture(value) {
        this._autoCapture = value;
    }

    /**
     * @returns {string}
     */
    get shopName() {
        return this._shopName;
    }

    /**
     * @param value {string}
     */
    set shopName(value) {
        this._shopName = value;
    }

    /**
     * @returns {string}
     */
    get country() {
        return this._country;
    }

    /**
     * @param value {string}
     */
    set country(value) {
        this._country = value;
    }

    /**
     * @returns {string}
     */
    get language() {
        return this._language;
    }

    /**
     * @param value {string}
     */
    set language(value) {
        this._language = value;
    }

    /**
     * @returns {string}
     */
    get ccToken() {
        return this._ccToken;
    }

    /**
     * @param value {string}
     */
    set ccToken(value) {
        this._ccToken = value;
    }

    /**
     * @returns {string}
     */
    get paymentDisplayType() {
        return this._paymentDisplayType;
    }

    /**
     * @param value {string}
     */
    set paymentDisplayType(value) {
        this._paymentDisplayType = value;
    }

    /**
     * @returns {string}
     */
    get paymentDisplayComponent() {
        return this._paymentDisplayComponent;
    }

    /**
     * @param value {string}
     */
    set paymentDisplayComponent(value) {
        this._paymentDisplayComponent = value;
    }


    serialize() {
        return {
            paymentType: this.paymentType,
            agreement: this.agreement ? this.agreement.serialize() : null,
            autoCapture: this.autoCapture,
            shopName: this.shopName,
            country: this.country,
            language: this.language,
            ccToken: this.ccToken,
            paymentDisplayType: this.paymentDisplayType,
            paymentDisplayComponent: this.paymentDisplayComponent
        };
    }

    /**
     * @param jsonValue {json}
     */
    static fromJson(jsonValue) {
        let config = new Configuration();
        config.paymentType = jsonValue.paymentType ? jsonValue.paymentType : null;
        config.agreement = jsonValue.agreement ? Agreement.fromJson(jsonValue.agreement) : null;
        config.autoCapture = jsonValue.autoCapture ? jsonValue.autoCapture : false;
        config.shopName = jsonValue.shopName ? jsonValue.shopName : null;
        config.country = jsonValue.country ? jsonValue.country : null;
        config.language = jsonValue.language ? jsonValue.language : null;
        config.ccToken = jsonValue.ccToken ? jsonValue.ccToken : null;
        config.paymentDisplayType = jsonValue.paymentDisplayType ? jsonValue.paymentDisplayType : null;
        config.paymentDisplayComponent = jsonValue.paymentDisplayComponent ? jsonValue.paymentDisplayComponent : null;

        return config;
    }
}

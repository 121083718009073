import {Address} from "./Address.js";
import {Customer} from "./Customer.js";

export class CustomerBuilder {


    /**
     * customer {Customer}
     */
    customer;

    constructor() {
        this.customer = new Customer();
    }

    /**
     * @param value {string}
     * @returns {CustomerBuilder}
     */
    setFirstName(value) {
        this.customer.firstName = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {CustomerBuilder}
     */
    setLastName(value) {
        this.customer.lastName = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {CustomerBuilder}
     */
    setEmail(value) {
        this.customer.email = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {CustomerBuilder}
     */
    setPhoneNumber(value) {
        this.customer.phoneNumber = value;
        return this;
    }

    /**
     * @param value {Address}
     * @returns {CustomerBuilder}
     */
    setBillingAddress(value) {
        this.customer.billingAddress = value;
        return this;
    }

    /**
     * @param value {Address}
     * @returns {CustomerBuilder}
     */
    setShippingAddress(value) {
        this.customer.shippingAddress = value;
        return this;
    }

    /**
     * @param value {string} in format of yyyy-MM-dd (example: 1999-10-16)
     * @returns {CustomerBuilder}
     */
    setBirthdate(value) {
        this.customer.birthdate = value;
        return this;
    }

    /**
     *
     * @param value {string}
     * @returns {CustomerBuilder}
     */
    setUsername(value) {
        this.customer.username = value;
        return this;
    }

    /**
     * @returns {Customer}
     */
    build() {
        return this.customer;
    }
}
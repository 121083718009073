import {PaymentMethodMetadata} from "./PaymentMethodMetadata.js";

export class PaymentMethod {

    /**
     *
     * @param id {string}
     * @param description {string}
     * @param logoUrl {string}
     * @param paymentMethodMetadata {PaymentMethodMetadata}
     * @param name {string}
     */
    constructor(id, description, logoUrl, paymentMethodMetadata, name) {
        this._id = id;
        this._description = description;
        this._logoUrl = logoUrl;
        this._paymentMethodMetadata = paymentMethodMetadata;
        this._name = name;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get logoUrl() {
        return this._logoUrl;
    }

    set logoUrl(value) {
        this._logoUrl = value;
    }

    get paymentMethodMetadata() {
        return this._paymentMethodMetadata;
    }

    set paymentMethodMetadata(value) {
        this._paymentMethodMetadata = value;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }
}
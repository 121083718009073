/**
 * Recognized payment status
 * @type {{NEW: string, SUCCEEDED: string, ERROR: string, FAILED: string, PENDING: string, CANCELLED: string, DECLINED: string, UNKNOWN: string}}
 */
export const PaymentStatus = {
    NEW : 'NEW',
    SUCCEEDED : 'SUCCEEDED',
    ERROR : 'ERROR',
    FAILED : 'FAILED',
    PENDING : 'PENDING',
    CANCELLED : 'CANCELLED',
    DECLINED : 'DECLINED',
    UNKNOWN : 'UNKNOWN',
}
import {Browser} from "./Browser.js";

export class Context {

    /**
     * @param browser {Browser}
     */
    constructor(browser) {
        this._browser = browser;
    }

    /**
     * @returns {Browser}
     */
    get browser() {
        return this._browser;
    }

    /**
     * @param value {Browser}
     */
    set browser(value) {
        this._browser = value;
    }

    serialize() {
        return {browser: this.browser ? this.browser.serialize() : null};
    }

    /**
     * @param value {json}
     */
    static fromJson(value) {
        let browser = value.browser ? Browser.fromJson(value.browser) : null;
        return new Context(browser);
    }
}
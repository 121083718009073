import {OrderLine} from "./OrderLine.js";

export class OrderLineBuilder {

    /**
     * agreement {OrderLine}
     */
    orderLine;

    /**
     *
     * @param itemId {string}
     * @param description {string}
     * @param quantity {number}
     * @param unitPrice {number}
     */
    constructor(itemId, description, quantity, unitPrice) {
        this.orderLine = new OrderLine(itemId, description, quantity, unitPrice);
    }

    /**
     * @param value {string}
     * @returns {OrderLineBuilder}
     */
    setUnitCode(value) {
        this.orderLine.unitCode = value;
        return this;
    }

    /**
     * @param value {number}
     * @returns {OrderLineBuilder}
     */
    setTaxPercent(value) {
        this.orderLine.taxPercent = value;
        return this;
    }

    /**
     * @param value {number}
     * @returns {OrderLineBuilder}
     */
    setTaxAmount(value) {
        this.orderLine.taxAmount = value;
        return this;
    }

    /**
     * @param value {number}
     * @returns {OrderLineBuilder}
     */
    setDiscountPercent(value) {
        this.orderLine.discountPercent = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {OrderLineBuilder}
     */
    setGoodsType(value) {
        this.orderLine.goodsType = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {OrderLineBuilder}
     */
    setImageUrl(value) {
        this.orderLine.imageUrl = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {OrderLineBuilder}
     */
    setProductUrl(value) {
        this.orderLine.productUrl = value;
        return this;
    }

    /**
     * @returns {OrderLine}
     */
    build() {
        return this.orderLine;
    }
}
export class Browser {

    /**
     * @param agent {string}
     * @param language {string}
     * @param origin {string}
     * @param screenHeight {number}
     * @param screenWidth {number}
     */
    constructor(agent, language, origin, screenHeight, screenWidth) {
        this._agent = agent;
        this._language = language;
        this._origin = origin;
        this._screenHeight = screenHeight;
        this._screenWidth = screenWidth;
    }

    /**
     * @returns {string}
     */
    get agent() {
        return this._agent;
    }

    /**
     * @param value {string}
     */
    set agent(value) {
        this._agent = value;
    }

    /**
     * @returns {string}
     */
    get language() {
        return this._language;
    }

    /**
     * @param value {string}
     */
    set language(value) {
        this._language = value;
    }

    /**
     * @returns {string}
     */
    get origin() {
        return this._origin;
    }

    /**
     * @param value
     */
    set origin(value) {
        this._origin = value;
    }

    /**
     * @returns {number}
     */
    get screenHeight() {
        return this._screenHeight;
    }

    /**
     * @param value {number}
     */
    set screenHeight(value) {
        this._screenHeight = value;
    }

    /**
     * @returns {number}
     */
    get screenWidth() {
        return this._screenWidth;
    }

    /**
     * @param value {number}
     */
    set screenWidth(value) {
        this._screenWidth = value;
    }

    serialize() {
        return {
            agent: this.agent,
            language: this.language,
            origin: this.origin,
            resolution: this.screenWidth + "x" + this.screenHeight,
        };
    }

    /**
     * @param value {json}
     */
    static fromJson(value) {
        let agent = value.agent ? value.agent : null;
        let language = value.language ? value.language : null;
        let origin = value.origin ? value.origin : null;
        let screenWidth = value.resolution ? value.resolution.split('x')[0] : null;
        let screenHeight = value.resolution ? value.resolution.split('x')[1] : null;
        return new Browser(agent, language,origin, screenHeight, screenWidth);
    }
}
export class OrderLine {

    /**
     *
     * @param itemId {string}
     * @param description {string}
     * @param quantity {number}
     * @param unitPrice {number}
     */
    constructor(itemId, description, quantity, unitPrice) {
        this._itemId = itemId;
        this._description = description;
        this._unitPrice = unitPrice;
        this._quantity = quantity;
    }

    /**
     * @returns {string}
     */
    get itemId() {
        return this._itemId;
    }

    /**
     * @param value {string}
     */
    set itemId(value) {
        this._itemId = value;
    }

    /**
     * @returns {string}
     */
    get description() {
        return this._description;
    }

    /**
     * @param value {string}
     */
    set description(value) {
        this._description = value;
    }

    /**
     * @returns {Amount}
     */
    get unitPrice() {
        return this._unitPrice;
    }

    /**
     * @param value {Amount}
     */
    set unitPrice(value) {
        this._unitPrice = value;
    }

    /**
     * @returns {number}
     */
    get quantity() {
        return this._quantity;
    }

    /**
     * @param value {number}
     */
    set quantity(value) {
        this._quantity = value;
    }

    /**
     * @returns {string}
     */
    get unitCode() {
        return this._unitCode;
    }

    /**
     * @param value {string}
     */
    set unitCode(value) {
        this._unitCode = value;
    }

    /**
     * @returns {number}
     */
    get taxPercent() {
        return this._taxPercent;
    }

    /**
     * @param value {number}
     */
    set taxPercent(value) {
        this._taxPercent = value;
    }

    /**
     * @returns {number}
     */
    get taxAmount() {
        return this._taxAmount;
    }

    /**
     * @param value {number}
     */
    set taxAmount(value) {
        this._taxAmount = value;
    }

    /**
     * @returns {number}
     */
    get discountPercent() {
        return this._discountPercent;
    }

    /**
     * @param value {number}
     */
    set discountPercent(value) {
        this._discountPercent = value;
    }

    /**
     * @returns {string}
     */
    get goodsType() {
        return this._goodsType;
    }

    /**
     * @param value {string}
     */
    set goodsType(value) {
        this._goodsType = value;
    }

    /**
     * @returns {string}
     */
    get imageUrl() {
        return this._imageUrl;
    }

    /**
     * @param value {string}
     */
    set imageUrl(value) {
        this._imageUrl = value;
    }

    /**
     * @returns {string}
     */
    get productUrl() {
        return this._productUrl;
    }

    /**
     * @param value {string}
     */
    set productUrl(value) {
        this._productUrl = value;
    }


    serialize() {
        return {
            itemId: this.itemId,
            description: this.description,
            quantity: this.quantity,
            unitPrice: this.unitPrice,
            unitCode: this.unitCode,
            taxPercent: this.taxPercent,
            taxAmount: this.taxAmount,
            discountPercent: this.discountPercent,
            goodsType: this.goodsType,
            imageUrl: this.imageUrl,
            productUrl: this.productUrl
        };
    }

    /**
     * @param value {json}
     */
    static fromJson(value) {
        const itemId = value.itemId ? value.itemId : null;
        const description = value.description ? value.description : null;
        const quantity = value.quantity ? value.quantity : null;
        const unitPrice = value.unitPrice ? value.unitPrice : null;
        const unitCode = value.unitCode ? value.unitCode : null;
        const taxPercent = value.taxPercent ? value.taxPercent : null;
        const taxAmount = value.taxAmount ? value.taxAmount : null;
        const discountPercent = value.discountPercent ? value.discountPercent : null;
        const goodsType = value.goodsType ? value.goodsType : null;
        const imageUrl = value.imageUrl ? value.imageUrl : null;
        const productUrl = value.productUrl ? value.productUrl : null;

        const ol= new OrderLine(itemId, description, quantity, unitPrice);
        ol.unitCode = unitCode;
        ol.taxPercent = taxPercent;
        ol.taxAmount = taxAmount;
        ol.discountPercent = discountPercent;
        ol.goodsType = goodsType;
        ol.imageUrl = imageUrl;
        ol.productUrl = productUrl;
        return ol;
    }
}
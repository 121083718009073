import {Configuration} from "./Configuration.js";
import {PaymentType} from "./PaymentType.js";
import {Agreement} from "./Agreement.js";
import {PaymentDisplayType} from "./PaymentDisplayType.js";

export class ConfigurationBuilder {

    /**
     * configuration {Configuration}
     */
    configuration;

    constructor() {
        this.configuration = new Configuration();
        this.configuration.paymentType = PaymentType.PAYMENT;
        this.configuration.autoCapture = false;
        this.configuration.paymentDisplayType = PaymentDisplayType.SCRIPT;
    }

    /**
     * @param value {PaymentType}
     * @returns {ConfigurationBuilder}
     */
    setTypePayment(value) {
        this.configuration.paymentType = value;
        return this;
    }

    /**
     * @param value {Agreement}
     * @returns {ConfigurationBuilder}
     */
    setAgreement(value) {
        this.configuration.agreement = value;
        return this;
    }

    /**
     * @param value {boolean}
     * @returns {ConfigurationBuilder}
     */
    setAutoCapture(value) {
        this.configuration.autoCapture = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {ConfigurationBuilder}
     */
    setShopName(value) {
        this.configuration.shopName = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {ConfigurationBuilder}
     */
    setCountry(value) {
        this.configuration.country = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {ConfigurationBuilder}
     */
    setLanguage(value) {
        this.configuration.language = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {ConfigurationBuilder}
     */
    setCcToken(value) {
        this.configuration.ccToken = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {ConfigurationBuilder}
     */
    setPaymentDisplayType(value) {
        this.configuration.paymentDisplayType = value;
        return this;
    }

    /**
     * @param value {string}
     * @returns {ConfigurationBuilder}
     */
    setPaymentDisplayComponent(value) {
        this.configuration.paymentDisplayComponent = value;
        return this;
    }

    /**
     * @returns {Configuration}
     */
    build() {
        return this.configuration;
    }
}

export class PaymentMethodMetadata {

    /**
     *
     * @param terminalName {string}
     * @param merchantId {string}
     * @param canUseCredit {boolean}
     * @param canIssueNewCredit {boolean}
     */
    constructor(terminalName, merchantId, canUseCredit, canIssueNewCredit) {
        this._terminalName = terminalName;
        this._merchantId = merchantId;
        this._canUseCredit = canUseCredit;
        this._canIssueNewCredit = canIssueNewCredit;
    }


    get terminalName() {
        return this._terminalName;
    }

    set terminalName(value) {
        this._terminalName = value;
    }

    get merchantId() {
        return this._merchantId;
    }

    set merchantId(value) {
        this._merchantId = value;
    }

    get canUseCredit() {
        return this._canUseCredit;
    }

    set canUseCredit(value) {
        this._canUseCredit = value;
    }

    get canIssueNewCredit() {
        return this._canIssueNewCredit;
    }

    set canIssueNewCredit(value) {
        this._canIssueNewCredit = value;
    }
}
import {AltaPayApplePay} from "./AltaPayApplePay.js";

/**
 * @class
 * @classdesc AltaPayPaymentMethodFactory is an object that provides helper functions to create payment specific JS handlers
 * @hideconstructor
 */
export class AltaPayPaymentMethodFactory {

    /**
     *
     * @param token
     * @param sessionId
     * @param paymentMethodId
     * @param order
     * @param data
     * @returns {AltaPayApplePay}
     * @constructor
     */
    ApplePay(token, sessionId, paymentMethodId, order, data) {
        return new AltaPayApplePay(token, sessionId, paymentMethodId, order, data);
    }
}
import {IAltaPay} from './IAltaPay.js';
import {AltaPay} from './AltaPay.js';
import {AltaPayFactory} from "./AltaPayFactory.js";
import {AltaPayPaymentMethodFactory} from "./methods/AltaPayPaymentMethodFactory.js";
import {AltaPayUtils} from "./AltaPayUtils.js";

(function () {


    if (!window.Altapay) {
        /**
         * @type {IAltaPay}
         */
        const _altapay = new AltaPay();
        window.Altapay = _altapay;
        window.AltaPay = _altapay;
    }

    if(!window.AltaPayFactory) {
        window.AltaPayFactory = new AltaPayFactory();
    }

    if(!window.AltaPayPaymentMethodFactory) {
        window.AltaPayPaymentMethodFactory = new AltaPayPaymentMethodFactory();
    }

    if(!window.AltaPayUtils) {
        window.AltaPayUtils = new AltaPayUtils();
    }

})();

